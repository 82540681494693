import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { useLottie } from "lottie-react";
import nzBloodAnimationData from "../../../assets/lottie/01_NZ_Blood_R5_PNGLottie_30fps.json";
import tokaTuAkeAnimationData from "../../../assets/lottie/04_Toka_Tu_Ake_R3_PNGLottie_30fps.json";
import tsbBankAnimationData from "../../../assets/lottie/02_TSB_Bank_R3_PNGLottie_30fps.json";
import teWaoNuiAnimationData from "../../../assets/lottie/03_TeWaoNui_R4_PNGLottie_30fps.json";
import RightArrow from "../../logo-components/right-arrow";
import RightDiagonalArrow from "../../logo-components/right-diagonal-arrow";
import { useGameStore } from "../game-store";
import { GameObject } from "./game-object";
import { allCaseStudies, backButton } from "../game-data";
const tokaTuAkeStill = require("../../../assets/images/Toka_Tu_Ake.png");
const tsbBankStill = require("../../../assets/images/TSB_Bank.png");
const teWaoNuiStill = require("../../../assets/images/Te_Wao_Nui.png");
const nzBloodStill = require("../../../assets/images/NZ_Blood.png");

type GridItemProps = {
  bg?: string;
  children?: React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  style?: React.CSSProperties;
};

const GridItem: React.FC<GridItemProps> = ({
  bg,
  children,
  colSpan,
  rowSpan,
  style,
}) => (
  <Box
    bg={bg || "transparent"}
    height="100%"
    display="flex"
    gridColumn={colSpan ? `span ${colSpan}` : undefined}
    gridRow={rowSpan ? `span ${rowSpan}` : undefined}
    style={style}
  >
    {children}
  </Box>
);

export const GameObjectScene = () => {
  // Animation options
  const animationOptions = {
    loop: true,
    autoplay: true,
  };

  const nzBloodLottie = useLottie({
    ...animationOptions,
    animationData: nzBloodAnimationData,
  });
  const tokaTuAkeLottie = useLottie({
    ...animationOptions,
    animationData: tokaTuAkeAnimationData,
  });
  const tsbBankLottie = useLottie({
    ...animationOptions,
    animationData: tsbBankAnimationData,
  });
  const teWaoNuiLottie = useLottie({
    ...animationOptions,
    animationData: teWaoNuiAnimationData,
  });

  const { currentArtBoardFound, isItemSelected } = useGameStore();

  return (
    <Box
      position="relative" // Set position to relative
      height="100vh"
      width="100vw"
    >
      {!isItemSelected ? (
        allCaseStudies.map((item, index) => {
          return <GameObject key={item.artboard} {...item} />;
        })
      ) : (
        <GameObject {...backButton} />
      )}

      <Box
        height="100%"
        width="100%"
        display="grid"
        gridTemplateColumns="repeat(5, 1fr)"
        gridTemplateRows="repeat(3, 1fr)"
      >
        {/* NZ Blood Section */}
        <GridItem colSpan={2} rowSpan={2}>
          <Box
            position="relative" // Set to relative to contain absolute positioning
            height="100%"
            width="100%"
          >
            <Box
              position="absolute" // Absolute positioning
              top={0}
              left={0}
              p={50}
              bg={currentArtBoardFound === "NZ_Blood" ? "#D6193A" : "#0F46D8"}
              width="50%" // Width corresponds to one grid cell in the merged area
              height="50%" // Height corresponds to one grid cell in the merged area
              color={"white"}
              transition="background-color 0.3s ease-in-out" // Added transition
            >
              <Heading pb={5}>NZ Blood</Heading> {/* Heading */}
              <Text fontFamily={"Montserrat"} fontSize={"20"} lineHeight={1.3}>
                Providing the right hybrid cloud solution, so they could focus
                on saving lives
              </Text>
              <Box position="absolute" right={50} bottom={50}>
                <RightDiagonalArrow />
              </Box>
            </Box>
            {/* Other content here */}
            <Box
              className="lottie-animation"
              position="absolute" // Absolute positioning
              bottom={0}
              left={0}
              style={{
                display: currentArtBoardFound === "NZ_Blood" ? "block" : "none",
              }}
              transition="background-color 0.3s ease-in-out" // Added transition
            >
              {nzBloodLottie.View}
            </Box>
            {/* Lottie spans both columns */}
            {/* White background */}
            <Box
              position="absolute"
              bottom={0}
              left={0}
              height="50%"
              width="100%"
              backgroundColor={
                currentArtBoardFound !== "NZ_Blood" ? "white" : "transparent"
              }
              transition="background-color 0.3s ease-in-out" // Added transition
            >
              {/* Placeholder image */}
              <Image
                src={nzBloodStill}
                alt="NZ_Blood"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  display:
                    currentArtBoardFound !== "NZ_Blood" ? "block" : "none",
                }}
                transition="background-color 0.3s ease-in-out" // Added transition
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem key={5} bg={"transparent"}>
          <Box
            bg={currentArtBoardFound === "Toka_Tu_Ake" ? "#1672C5" : "#0F46D8"}
            width="100%" // Width corresponds to one grid cell in the merged area
            height="100%" // Height corresponds to one grid cell in the merged area
            color={"white"}
            transition="background-color 0.3s ease-in-out" // Added transition
            position={"relative"}
          >
            <Box>
              <Heading mt={12} pb={5} px={12}>
                Toka Tu Ake
              </Heading>
              <Text
                fontFamily={"Montserrat"}
                fontSize={"20"}
                lineHeight={1.3}
                px={12}
              >
                Bringing eight major insurers into a single, secure system
              </Text>
              <Box position="absolute" right={50} bottom={50}>
                <RightArrow />
              </Box>
            </Box>
          </Box>
        </GridItem>

        {/* Toka Tu Ake Section */}
        <GridItem colSpan={2} rowSpan={1} style={{ position: "relative" }}>
          {/* White background box */}
          <Box
            position="absolute"
            left={0}
            width="50%"
            height="100%"
            backgroundColor={
              currentArtBoardFound !== "Toka_Tu_Ake" ? "white" : "transparent"
            }
            transition="background-color 0.3s ease-in-out" // Added transition
          />
          <Image
            src={tokaTuAkeStill}
            alt="Toka_Tu_Ake"
            style={{
              display:
                currentArtBoardFound !== "Toka_Tu_Ake" ? "block" : "none",
            }}
            zIndex={1}
            transition="background-color 0.3s ease-in-out" // Added transition
          />
          <Box display="flex" alignItems="center" height="100%" width="100%">
            <Box
              className="lottie-animation"
              style={{
                display:
                  currentArtBoardFound === "Toka_Tu_Ake" ? "block" : "none",
              }}
              transition="background-color 0.3s ease-in-out" // Added transition
            >
              {tokaTuAkeLottie.View}
            </Box>
          </Box>
        </GridItem>

        <GridItem />
        <GridItem key={6} bg={"transparent"}>
          <Box
            bg={currentArtBoardFound === "Te_Wao_Nui" ? "#8FBF39" : "#0F46D8"}
            p={50}
            width="100%"
            height="100%"
            color={"white"}
            transition="background-color 0.3s ease-in-out"
            position="relative"
          >
            <Heading pb={5}>Te Wao Nui</Heading>
            <Text fontFamily={"Montserrat"} fontSize={"20"} lineHeight={1.3}>
              An interactive gaming experience designed for brave young tamariki
              in the hospital
            </Text>
            <Box position="absolute" right={50} bottom={50}>
              <RightDiagonalArrow />
            </Box>
          </Box>

          <Box
            className="lottie-animation"
            position="absolute"
            right={0}
            bottom={0}
            height="66.66%"
            zIndex={2}
            style={{
              display: currentArtBoardFound === "Te_Wao_Nui" ? "flex" : "none",
            }}
            transition="opacity 0.3s ease-in-out"
          >
            {teWaoNuiLottie.View}
          </Box>

          <Box
            position="absolute"
            right={0}
            width="20%"
            height="100%"
            backgroundColor={
              currentArtBoardFound !== "Te_Wao_Nui" ? "white" : "transparent"
            }
            transition="background-color 0.3s ease-in-out"
          />

          <Image
            src={teWaoNuiStill}
            right={0}
            position="absolute"
            bottom={0}
            zIndex={2}
            height="66.66%"
            alt="Te Wao Nui"
            style={{
              display: currentArtBoardFound !== "Te_Wao_Nui" ? "block" : "none",
            }}
            transition="opacity 0.3s ease-in-out"
          />
        </GridItem>

        <GridItem />
        <GridItem />

        {/* TSB Bank Section with NZ Blood Content */}
        <GridItem colSpan={1} rowSpan={1} style={{ position: "relative" }}>
          <Box
            bg={currentArtBoardFound === "TSB_Bank" ? "#1BAD81" : "#0F46D8"}
            p={50} // Padding
            height="100%" // Height corresponds to one grid cell
            position="relative"
            width={"100%"} // Make sure it only occupies the grid cell it's in
            top={0}
            left={0}
            color={"white"}
          >
            <Heading pb={5}>TSB Bank</Heading> {/* Heading */}
            <Text
              fontFamily={"Montserrat"}
              fontSize={"20"}
              lineHeight={1.3}
              pr={25}
            >
              The mammoth migration - shifting 50TB of TSB's data to Datacom's
              cloud
            </Text>
            <Box position="absolute" right={50} bottom={50}>
              <RightArrow />
            </Box>
          </Box>
        </GridItem>

        {/* Empty cell to the right of the TSB Bank heading */}
        <GridItem colSpan={2} rowSpan={1} style={{ position: "relative" }}>
          {/* TSB Bank Lottie */}
          <Box
            className="lottie-animation"
            style={{
              position: "absolute",
              left: "-16.4%",
              display: currentArtBoardFound === "TSB_Bank" ? "flex" : "none",
            }}
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            {tsbBankLottie.View}
          </Box>

          {/* White cell to the right of the TSB Bank heading */}
          <Box
            style={{
              position: "absolute",
              left: "0%",
              backgroundColor:
                currentArtBoardFound !== "TSB_Bank" ? "white" : "transparent",
              width: "50%",
              height: "100%",
            }}
          />

          {/* Placeholder image */}
          <Image
            src={tsbBankStill}
            alt="TSB Bank"
            style={{
              position: "absolute",
              left: "-12%",
              display: currentArtBoardFound !== "TSB_Bank" ? "block" : "none",
            }}
          />
        </GridItem>
      </Box>
    </Box>
  );
};
