interface QuoteLogoProps {
  color?: string;
}

const QuoteLogo: React.FC<QuoteLogoProps> = ({ color = "#296044" }) => (
  <svg
    width="43"
    height="34"
    viewBox="0 0 43 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="&#226;&#128;&#156;"
      d="M20.0223 6.04624C16.9877 6.59414 14.48 8.70147 12.4991 12.3682C11.5298 14.1805 10.8554 15.9717 10.4761 17.7419C10.4339 17.9526 10.4129 18.1423 10.4129 18.3109C10.4129 18.4373 10.3707 18.7534 10.2864 19.2592H17.1774L14.2693 33.0411H0.803467L3.52192 20.3339C4.82846 14.0962 7.0833 9.29152 10.2864 5.9198C13.4896 2.54807 17.1563 0.609335 21.2867 0.103577L20.0223 6.04624ZM41.3273 6.04624C38.925 6.42555 36.8809 7.7321 35.195 9.96586C33.5092 12.1996 32.3712 14.7706 31.7812 17.6787C31.6969 17.9316 31.6336 18.1844 31.5915 18.4373C31.5915 18.6902 31.5915 18.9641 31.5915 19.2592H38.4824L35.5743 33.0411H22.0453L24.7638 20.3339C25.8174 15.3185 27.8194 10.8509 30.7696 6.93131C33.7199 2.96954 37.6606 0.693628 42.5917 0.103577L41.3273 6.04624Z"
      fill={color}
    />
  </svg>
);

export default QuoteLogo;
