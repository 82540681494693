const RightDiagonalArrow = () => (
  <svg
    width="73"
    height="73"
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.479 54.4318L54.7617 54.4319L54.7617 19.1492"
      stroke="#EDF1FF"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M18.4883 18.1604L54.7608 54.433"
      stroke="#EDF1FF"
      strokeWidth="4"
    />
  </svg>
);

export default RightDiagonalArrow;
