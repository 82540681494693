import { Box, Grid, Image, Text, Heading, Flex } from "@chakra-ui/react";
import { IGameObject } from "../../common/models";
import { FC } from "react";
import BackArrow from "../logo-components/back-arrow";
import QuoteLogo from "../logo-components/quote-logo";
import nzBloodSquareAnimationData from "../../assets/lottie/NZ_Blood_Square.json";
import tokaTuAkeSquareAnimationData from "../../assets/lottie/Toka_Tu_Ake_Square.json";
import tsbBankSquareAnimationData from "../../assets/lottie/TSB_Bank_Square.json";
import teWaoNuiSquareAnimationData from "../../assets/lottie/Te_Wao_Nui_Square.json";
import { useLottie } from "lottie-react";
const nzBloodCover = require("../../assets/images/nzbloodcover.png");
const twnCover = require("../../assets/images/twnCover.png");
const tsbCover = require("../../assets/images/tsbCover.png");
const eqcCover = require("../../assets/images/eqcCover.png");

// Animation options
const animationOptions = {
  loop: true,
  autoplay: true,
};

const gameArtMap = {
  NZ_Blood: { animation: nzBloodSquareAnimationData, image: nzBloodCover },
  Toka_Tu_Ake: { animation: tokaTuAkeSquareAnimationData, image: eqcCover },
  Te_Wao_Nui: { animation: teWaoNuiSquareAnimationData, image: twnCover },
  TSB_Bank: { animation: tsbBankSquareAnimationData, image: tsbCover },
};

const CaseStudyDetails: FC<IGameObject> = (props) => {
  const { artboard } = props;

  const LottieComponent = useLottie({
    ...animationOptions,
    animationData: gameArtMap[artboard]?.animation,
  }).View;

  const ImageSrc = gameArtMap[artboard]?.image;

  return (
    <Box position="relative" width="100vw" height="100vh">
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(3, 1fr)"
        width="60vw"
        height="100vh"
        position="relative"
        zIndex={30}
        fontFamily={"Montserrat"}
      >
        <Box
          gridColumn="1 / span 2"
          gridRow="1 / span 1"
          bg={props.blurbColour}
          color={"white"}
          padding={16}
        >
          <Heading fontWeight={"semibold"} fontSize={"5xl"} pb={5}>
            {props.heading}
          </Heading>
          <Text fontSize={"xl"}>{props.blurb}</Text>
        </Box>
        <Box
          gridColumn="3 / span 1"
          gridRow="1 / span 1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg={props.statisticsColour}
          padding={16}
          color={"white"}
        >
          <Heading fontSize="7xl" fontWeight={"normal"} pb={5}>
            {props.statistic1}
          </Heading>
          <Text align="center" fontSize="xl">
            {props.statistic1text}
          </Text>
        </Box>
        <Box gridColumn="1 / span 1" gridRow="2 / span 1">
          <Box className="lottie-animation">{LottieComponent}</Box>
        </Box>
        <Box
          gridColumn="2 / span 1"
          gridRow="2 / span 1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg={props.statisticsColour}
          padding={16}
          color={"white"}
        >
          <Heading fontSize="7xl" fontWeight={"normal"} pb={5}>
            {props.statistic2}
          </Heading>
          <Text align="center" fontSize="xl">
            {props.statistic2text}
          </Text>
        </Box>
        <Box
          gridColumn="1 / span 1"
          gridRow="3 / span 1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg={props.statisticsColour}
          padding={16}
          color={"white"}
        >
          <Heading fontSize="7xl" fontWeight={"normal"} pb={5}>
            {props.statistic3}
          </Heading>
          <Text align="center" fontSize="xl">
            {props.statistic3text}
          </Text>
        </Box>
        <Box
          gridColumn="2 / span 2"
          gridRow="3 / span 1"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          bg={props.quoteColour}
          color={props.statisticsColour}
          padding={16}
        >
          <QuoteLogo color={props.statisticsColour} />
          <Text fontSize={"large"} mt={4}>
            {props.quote}
          </Text>
          <Flex
            flexDirection="column"
            alignItems="flex-end"
            width="100%"
            pt={5}
          >
            <Text fontWeight={"semibold"}>{props.quoteName}</Text>
            <Text>{props.quoteCompany}</Text>
          </Flex>
        </Box>
      </Grid>
      <Image
        src={ImageSrc}
        objectFit="cover"
        width="61%"
        height="100%"
        position="absolute"
        right={0}
        top={0}
      />
      <Flex
        width="200px"
        height="200px"
        backgroundColor={props.backButtonBackgroundColour}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top={0} // Adjust as per your requirement
        right={0} // Adjust as per your requirement
      >
        <BackArrow />
      </Flex>
    </Box>
  );
};

export default CaseStudyDetails;
