const RightArrow = () => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6074 52.1826L51.5561 27.2341L26.6074 2.2854"
      stroke="#EDF1FF"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M0.259766 27.2346L51.5569 27.2346"
      stroke="#EDF1FF"
      strokeWidth="4"
    />
  </svg>
);

export default RightArrow;
