import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useLottie } from "lottie-react";
import { updateTransition, useGameStore } from "../game-stage/game-store";
import backgroundAnimationData from "../../assets/lottie/Grid-Transition_R1.json";

const GridAnimationLayer: React.FC = () => {
  const { isTransitionVisible } = useGameStore();

  // Animation options
  const animationOptions = {
    loop: true,
    autoplay: true,
  };

  const backgroundAnimationLottie = useLottie({
    ...animationOptions,
    animationData: backgroundAnimationData,
  });

  useEffect(() => {
    setTimeout(() => {
      updateTransition(false);
    }, 2000);
  }, [isTransitionVisible]);

  if (!isTransitionVisible) {
    return null; // Return null if isTransitionVisible is false
  }

  return (
    <Box
      className="lottie-animation"
      position="absolute"
      top="0"
      left="0"
      height="100%"
      width="100%"
      zIndex={70}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {backgroundAnimationLottie.View}
    </Box>
  );
};

export default GridAnimationLayer;
