import { FC, useEffect } from "react";
import { IPosition } from "../../../common/models";
import { useBodyPoseState } from "../../pose-detection/pose-detection";
import { updateCurrentArtBoardFound } from "../game-store";

interface IProp {
  position: IPosition;
  width: number;
  height: number;
  artboard: string;
  isFound: boolean;
  isHover: boolean;
}

export const GameObject: FC<IProp> = (props) => {
  const { pointers } = useBodyPoseState();

  useEffect(() => {
    for (let index = 0; index < pointers.length; index++) {
      const pos = pointers[index];

      const left = props.position.x;
      const right = props.position.x + props.width;
      const top = props.position.y;
      const bottom = props.position.y + props.height;
      const contains =
        pos.x > left && pos.x < right && pos.y > top && pos.y < bottom;

      if (contains && !props.isFound) {
        updateCurrentArtBoardFound(props.artboard);
        break;
      }
    }
  }, [
    pointers,
    props.artboard,
    props.height,
    props.isFound,
    props.position.x,
    props.position.y,
    props.width,
  ]);

  return <div />;
};
