import "./App.css";
import { Camera } from "./components/camera";
import { GameStage } from "./components/game-stage/game-stage";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <div className="relative w-full h-full">
        <GameStage />
        <Camera showCanvas={false} />
      </div>
    </ChakraProvider>
  );
}

export default App;
