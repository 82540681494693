import React, { useEffect, useRef, useState } from "react";
import { IPosition } from "../../../common/models";
import "./player-pointer.css"; // Import the CSS file
import { updateItemSelected, useGameStore } from "../game-store";

interface IProp {
  position: IPosition;
  height: number;
  width: number;
  isFound: boolean;
}

export const PlayerPointer: React.FC<IProp> = (props) => {
  const magnifierRef = useRef<HTMLDivElement | null>(null);
  const [position, setPos] = useState<IPosition | null>(null);
  const [animationKey, setAnimationKey] = useState<number>(Date.now());
  const { currentArtBoardFound, isItemSelected } = useGameStore();

  const onProgressComplete = () => {
    updateItemSelected(true);
    setAnimationKey(Date.now());
  };

  useEffect(() => {
    const magnifier = magnifierRef.current;

    if (magnifier) {
      const magnifierSize = 100; // Size of the magnifying glass
      const magnification = 2; // Magnification level

      const x = props.position.x - magnifierSize / 2;
      const y = props.position.y - magnifierSize / 2;

      magnifier.style.left = `${x}px`;
      magnifier.style.top = `${y}px`;
      magnifier.style.backgroundPosition = `-${x * magnification}px -${
        y * magnification
      }px`;
      magnifier.style.backgroundSize = `${
        window.innerWidth * magnification
      }px ${window.innerHeight * magnification}px`;
      magnifier.style.backgroundImage = `url(${document.body.style.backgroundImage})`;
    }
    setPos(props.position);
  }, [props.position]);

  // Reset animation when currentArtBoardFound changes
  useEffect(() => {
    setAnimationKey(Date.now());
  }, [currentArtBoardFound]);

  return (
    <div
      ref={magnifierRef}
      className="playerPointer"
      style={{
        height: `${props.height}px`,
        width: `${props.width}px`,
        left: `${props.position.x}px`,
        top: `${props.position.y}px`,
      }}
    >
      {position && !props.isFound && !isItemSelected ? (
        <svg
          key={animationKey}
          width={props.width}
          height={props.height}
          viewBox="0 0 110 110"
          className="fixed z-40"
          style={{
            left: `${props.position.x - 50}px`,
            top: `${props.position.y - 50}px`,
          }}
        >
          <defs>
            <radialGradient
              id="lensGradient"
              cx="50%"
              cy="50%"
              r="50%"
              fx="50%"
              fy="50%"
            >
              <stop
                offset="0%"
                style={{ stopColor: "rgba(255, 255, 255, 0.8)" }}
              />
              <stop
                offset="80%"
                style={{ stopColor: "rgba(255, 255, 255, 0.6)" }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "rgba(255, 255, 255, 0.3)" }}
              />
            </radialGradient>
          </defs>
          <circle cx="55" cy="55" r="50" fill="url(#lensGradient)" />
          <circle
            cx="55"
            cy="55"
            r="50"
            onAnimationEnd={onProgressComplete}
            strokeLinecap="round"
            strokeWidth={5}
            className="animated stroke-white fill-transparent show-progress"
          />
        </svg>
      ) : null}
    </div>
  );
};
