import { BackgroundScene } from "./background/background-scene";
import { PlayerScene } from "./player/player-scene";
import { GameObjectScene } from "./game-object/game-object-scene";
import { Screensaver } from "./screensaver/screensaver";
import GridAnimationLayer from "../transition/transition";
import { useGameStore } from "./game-store";
import CaseStudyDetails from "../case-study-details/case-study-details";
import { allCaseStudies } from "./game-data";

export const GameStage = () => {
  const { isTransitionVisible, isItemSelected, currentArtBoardFound } =
    useGameStore();
  const currentCaseStudy = allCaseStudies.find(
    (study) => study.artboard === currentArtBoardFound
  );

  return (
    <div className="absolute left-0 top-0 w-full h-full overflow-hidden z-0">
      <div className="absolute w-full h-full z-10">
        {/* Background with z-index 10 */}
        <BackgroundScene />
      </div>
      <div className="absolute w-full h-full z-20">
        {/* Game objects with z-index 20 */}
        <GameObjectScene />
      </div>
      {isItemSelected && currentCaseStudy && (
        <div className="absolute w-full h-full z-30">
          {/* Screensaver with z-index 30 */}
          <CaseStudyDetails {...currentCaseStudy} />
        </div>
      )}
      <div className="absolute w-full h-full z-40">
        {/* Screensaver with z-index 30 */}
        <Screensaver />
      </div>
      <div className="absolute w-full h-full z-50">
        {/* Player Hand with z-index 40 */}
        <PlayerScene />
      </div>
      {isTransitionVisible && (
        <div className="absolute w-full h-full z-60">
          {/* Grid Animation with z-index 40 */}
          <GridAnimationLayer />
        </div>
      )}
    </div>
  );
};
