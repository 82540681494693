import { useBodyPoseState } from "../../pose-detection/pose-detection";
import { PlayerPointer } from "./player-pointer";

export const PlayerScene = () => {
  const { pointers } = useBodyPoseState();

  const screenPointers = pointers.map((position, index) => {
    return (
      <PlayerPointer
        key={index}
        position={position}
        height={200}
        width={200}
        isFound={false}
      />
    );
  });

  return <> {screenPointers}</>;
};
