const BackArrow = () => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.0483 2.28171L3.09969 27.2303L28.0483 52.179"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path d="M54.396 27.2297L3.09886 27.2297" stroke="white" strokeWidth="4" />
  </svg>
);

export default BackArrow;
