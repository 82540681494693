import { Box } from "@chakra-ui/react";

export const BackgroundScene = () => {
  return (
    <Box pos="absolute" w="full" h="full" zIndex={40}>
      <video
        autoPlay
        loop
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src="https://res.cloudinary.com/dbecdb9ww/video/upload/v1714621100/casestudiesbackground.mp4" />
      </video>
    </Box>
  );
};
