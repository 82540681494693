import { useEffect, useRef, useState } from "react";
import { Box, Text, Center, VStack, Image } from "@chakra-ui/react";
import { useBodyPoseState } from "../../pose-detection/pose-detection";
import {
  updateCurrentArtBoardFound,
  updateItemSelected,
  updateTransition,
} from "../game-store";
const handWaveGif = require("../../../assets/gifs/hand_wave.gif");

export const Screensaver = () => {
  const ref = useRef<HTMLVideoElement>(null);

  const { pointers } = useBodyPoseState();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.play();
    }, 3000);
  }, [ref]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (pointers.length === 0) {
      timer = setTimeout(() => {
        updateCurrentArtBoardFound("");
        updateItemSelected(false);
        setIsVisible(() => pointers.length === 0);
      }, 10000);
    } else {
      updateTransition(isVisible);
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [pointers, isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <Box pos="absolute" w="full" h="full" zIndex={40}>
      <video
        ref={ref}
        autoPlay
        loop
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src="https://res.cloudinary.com/dbecdb9ww/video/upload/v1714621100/casestudiesbackground.mp4" />
      </video>
      <Center pos="absolute" w="full" h="full">
        <VStack
          spacing={2}
          textAlign="center"
          fontSize="6xl"
          color="white"
          p={40}
        >
          <Text as="h2" textShadow="lg" fontFamily={"Montserrat"}>
            In the face of a challenge, the best way <br /> to move forward is
            to start solving...
          </Text>
        </VStack>
      </Center>
      {/* New code for hand wave gif and text */}
      <Center
        pos="absolute"
        w="full"
        bottom="77px" // 80px padding from the bottom
      >
        <VStack spacing={2} textAlign="center">
          <Image
            src={handWaveGif} // Update this path
            alt="Hand wave gif"
            boxSize="60px" // Adjust as per your requirement
          />
          <Text
            fontSize="xl" // Adjust as per your requirement
            color="white"
            fontWeight="normal"
            textTransform="uppercase"
            fontFamily={"Montserrat"}
          >
            Raise your hand to interact
          </Text>
        </VStack>
      </Center>
    </Box>
  );
};
