import { IGameObject } from "../../common/models";

export type GameObjectArt =
  | "NZ_Blood"
  | "Toka_Tu_Ake"
  | "Te_Wao_Nui"
  | "TSB_Bank";

export const allCaseStudies: Array<IGameObject> = [
  {
    artboard: "NZ_Blood",
    height: 500,
    width: 500,
    isFound: false,
    isHover: false,
    position: {
      x: 0,
      y: 0,
    },
    heading: "NZ Blood",
    blurb:
      "New Zealand Blood Service (NZBS) is responsible for finding blood donors all over the country and collecting and distributing blood and plasma to hospitals for lifesaving treatments. We helped them get their cloud right, so they can focus on the big stuff.",
    quote:
      "We wanted to evolve and adapt to the changing circumstances and have our workloads in the public cloud. Datacom came in and worked with us to undertake a server migration … it very quickly turned into a holistic solution that was best for all our teams and systems.",
    statistic1: "30k",
    statistic1text: "lives saved every year",
    statistic2: "106k",
    statistic2text: "blood donations",
    statistic3: "24/7",
    statistic3text: "critical service",
    blurbColour: "#CB3636",
    statisticsColour: "#1E1A1B",
    quoteColour: "#FDFCF9",
    backButtonBackgroundColour: "#1E1A1B",
    quoteName: "Michael Lewis, Infrastructure Manager",
    quoteCompany: "New Zealand Blood Service",
  },
  {
    artboard: "Toka_Tu_Ake",
    height: 200,
    width: 500,
    isFound: false,
    isHover: false,
    position: {
      x: 708,
      y: 0,
    },
    heading: "Toka Tū Ake EQC",
    blurb:
      "In the past, claimants dealt directly with EQC and claims were handled internally. Under the new model, Toka Tū Ake EQC works in partnership with eight major insurers, and claimants deal via their own insurance company. This has vastly improved the claimant experience.",
    quote:
      "Having Datacom as a partner has given us the ability to provide solutions we couldn't do on our own. Our security team is 100% Datacom - they're engaged across all levels of EQC and they're always working to enhance the value of bringing them into the organisation.",
    statistic1: "8",
    statistic1text: "major insurers, one model",
    statistic2: "30%",
    statistic2text: "lift in maturity assessments",
    statistic3: "1945",
    statistic3text: "Supporting Kiwis Since",
    blurbColour: "#3170A8",
    statisticsColour: "#214567",
    quoteColour: "#EAF5F5",
    backButtonBackgroundColour: "#214567",
    quoteName: "Todd Skilton, Head of Information and Digital",
    quoteCompany: "Toka Tū Ake EQC",
  },
  {
    artboard: "Te_Wao_Nui",
    isHover: false,
    height: 500,
    width: 500,
    isFound: false,
    position: {
      x: 1000,
      y: 400,
    },
    heading: "Te Wao Nui",
    blurb:
      "Datacom was part of the team that helped create safe and welcoming spaces for the children that will come through the doors of Te Wao Nui, the Wellington Children's hospital. One part of the project saw the team create a massive interactive game wall that will give children and their families something to interact with in the admissions area.",
    quote:
      "The colour, the distraction and the movement of the interactive wall is the perfect solution to drawing a child over, to reduce their anxiety, to create a distraction, and also to get in a physical activity without having to touch or move things.",
    statistic1: "87k",
    statistic1text: "tamariki every year",
    statistic2: "866",
    statistic2text: "gamified donations in the first 9 months",
    statistic3: "7162",
    statistic3text: "games played in the first 9 months",
    blurbColour: "#74A50E",
    statisticsColour: "#296044",
    quoteColour: "#EDF3E2",
    backButtonBackgroundColour: "#296044",
    quoteName: "Sue Valentine, Sr Health Ops Project Manager",
    quoteCompany: "Wellington Hospitals Foundation",
  },
  {
    artboard: "TSB_Bank",
    height: 200,
    width: 500,
    isFound: false,
    isHover: false,
    position: {
      x: 400,
      y: 700,
    },
    heading: "TSB Bank",
    blurb:
      "Working with Datacom, TSB created a staged plan to migrate their servers and data without any downtime for their customers. It saw them migrate 250 servers and 50TB of data to Datacom's Cloud X platform in waves of 10 or 15 at a time, with up to three waves in a 24-hour period.",
    quote:
      "Customer didn't see any change or disruption, but they're getting a more reliable and secure service,” says CIO Julian Downs. “A big advantage is that this sets us up to provide more innovative products to consumers in the future.”",
    statistic1: "50TB",
    statistic1text: "of data transferred",
    statistic2: "250",
    statistic2text: "servers migrated",
    statistic3: "$3m",
    statistic3text: "avoided spend on hardware",
    blurbColour: "#29A37E",
    statisticsColour: "#072036",
    quoteColour: "#F4F3FF",
    backButtonBackgroundColour: "#072036",
  },
];

export const backButton = {
  artboard: "Back_Button",
  height: 400,
  width: 400,
  isFound: false,
  isHover: false,
  position: {
    x: 1920 - 400,
    y: 0,
  },
};
